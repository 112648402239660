import $ from 'jquery';

export default class VideoCursor {
    constructor() {
        this.$element = $('.js-tour-360');
        this.init();
    }

    init() {
        this.$element.each((index, el) => {
            this.initVideoCursor(el);
        });
    }

    initVideoCursor(el) {
        let cursorImg = $(el).find('.video-container-manual-play').attr('data-cursor');
        if(cursorImg != 'default') {
            $(el).find('.video-container-manual-play .video-cursor span').css('background-image', 'url(' + cursorImg + ')');
        } else {
            $(el).find('.video-container-manual-play .video-cursor').addClass('is-default');
        }
    }
}
