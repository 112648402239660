import $ from 'jquery';

var adroll_timeout;
var adroll_implicitConsent = false;

var adroll_adv_id = 'CN45LZAJTJDA7JRYDZ7F6J';
var adroll_pix_id = 'Y3QMCR4WDNHWVCJF5JTDQK';

var _onload = function () {
    if (adroll_implicitConsent) {
        if (document.readyState && !/loaded|complete/.test(document.readyState)) {
            setTimeout(_onload, 10);
            return;
        }
        if (!window.__adroll_loaded) {
            window.__adroll_loaded = true;
            setTimeout(_onload, 50);
            return;
        }
        var scr = document.createElement('script');
        var host =
            'https:' == document.location.protocol ? 'https://s.adroll.com' : 'http://a.adroll.com';
        scr.setAttribute('async', 'true');
        scr.type = 'text/javascript';
        scr.src = host + '/j/roundtrip.js';
        (
            (document.getElementsByTagName('head') || [null])[0] ||
            document.getElementsByTagName('script')[0].parentNode
        ).appendChild(scr);
    }
};

window.addEventListener('load', _onload, false);
// Listen for localisationSelectionToggleModal custom event
window.addEventListener('localisationSelectionToggleModal', function (e) {
    var modalVisible = e.detail;
    if (modalVisible === false && adroll_implicitConsent === false) {
        adroll_implicitConsent = true;
        clearTimeout(adroll_timeout);
        adroll_timeout = setTimeout(_onload, 200);
    }
});
var handleScroll = function () {
    if (adroll_implicitConsent === false) {
        adroll_implicitConsent = true;
        clearTimeout(adroll_timeout);
        setTimeout(_onload, 200);
    } else {
        window.removeEventListener('scroll', handleScroll);
    }
};
// Listen for scroll event
window.addEventListener('scroll', handleScroll);
