import $ from 'jquery';

const activeScrollerClassName = 'locator-modal__locator-section__nav-links-mobile__active';
const hiddenClassName = 'locator__main-container__hidden';

window.selectLanguage = function () {
    if ($(window).width() < 720) {
        $('.locator-modal__locator-section__select-language').trigger('click');
    }
}

window.backToLanguage = function () {
    // Check if it mobile or desktop
    if ($(window).width() < 720) {
        $('.locator-modal__locator-section__first-dropdowns__country-dropdown').css('display', 'none');
        $('.locator-modal__locator-section__first-dropdowns__language-dropdown').css('display', 'flex');
        $('.locator-modal__locator-section__nav-links-mobile').children()[1].classList = [];
        $('.locator-modal__locator-section__nav-links-mobile')
            .children()[0]
            .classList.add(activeScrollerClassName);
    }
};

window.selectCountry = function () {
    if ($(window).width() < 720) {
        $('.locator-modal__locator-section__select-place').trigger('click');
    }
}

window.backToCountry = function () {
    if ($(window).width() < 720) {
        $('.locator-modal__locator-section__distributor-dropdown').css('display', 'none');
        $('.locator-modal__locator-section__first-dropdowns__country-dropdown').css('display', 'flex');
        $('.locator-modal__locator-section__nav-links-mobile').children()[2].classList = [];
        $('.locator-modal__locator-section__nav-links-mobile')
            .children()[1]
            .classList.add(activeScrollerClassName);
    }
};

window.onClickFindAnother = function () {
    $('.locator-modal__locator-section').addClass('locator-modal__locator-section__animated');
    $('#locator-dropdown-section').css({ display: 'block' });
    $('#locator-confirm-section').css({ display: 'none' });
};

window.onClickFindAnotherMobile = function () {
    const isDistributorChosen = getCookie('isDistributorChosen');
    if (isDistributorChosen == 'true') {
        document.cookie = 'isDistributorChosen=false';
        $('#locator-confirm-section').hide(50);
        $('#locator-dropdown-section').show(50);
        $('.locator-modal__locator-section__header-confirm-mobile').css('display', 'none');
        $('.locator-modal__locator-section__nav-links-mobile').show(50);
        $('.locator-modal__locator-section__nav-links-mobile').css('display', 'flex');
        $('.locator-modal__locator-section__nav-links-mobile')
            .children()[0]
            .classList.add(activeScrollerClassName);
    } else {
        $('#locator-confirm-section').hide(50);
        $('#locator-dropdown-section').show(50);
        $('.locator-modal__locator-section__distributor-dropdown').show(50);
        $(
            '.locator-modal__locator-section__first-dropdowns__country-dropdown__mobile-back-button'
        ).show(50);
        $('.locator-modal__locator-section__first-dropdowns').css('margin', '0 auto');
        $('.locator-modal__locator-section__distributor-dropdown').css('display', 'flex');
        $('.locator-modal__locator-section__header').css('display', 'block');
        $('.locator-modal__locator-section__header-confirm-mobile').css('display', 'none');
        $('.locator-modal__locator-section__nav-links-mobile').show(50);
        $('.locator-modal__locator-section__nav-links-mobile').css('display', 'flex');
        $('.locator-modal__locator-section__nav-links-mobile')
            .children()[2]
            .classList.add(activeScrollerClassName);
    }
};

window.openLocator = function () {
    const locatorModal = $('.locator__main-container');
    if (locatorModal.hasClass(hiddenClassName)) {
        const isDistributorChosen = getCookie('isDistributorChosen');
        locatorModal.removeClass(hiddenClassName).fadeOut(0).fadeIn(500);
        $('body').css('overflow-y', 'hidden');
        $('#main-header-logo').addClass('black-logo');
        $('#menu-toggle').addClass('menu-toggle__close-button');
        $('#main-header').removeClass('sticky');
        $('#main-header').css('position', 'fixed');

        if (isDistributorChosen == 'true') {
            $('#locator-dropdown-section').css({ display: 'none' });
            $('#locator-confirm-section').css({ display: 'block' });
            if ($(window).width() > 720) {
                $('.locator-modal__locator-section__button-div').css({ display: 'none' });
            } else {
                $('.locator-modal__locator-section__button-div').css({ display: 'block' });
                $('.locator-modal__locator-section__button-div__confirm-button').css({
                    display: 'none',
                });
            }
            $('.locator-modal__locator-section__nav-links-mobile').css({ display: 'none' });
        }
    } else {
        if (navigator.userAgent.indexOf('Firefox') > 0) {
            $('body').css('overflow-y', 'auto');
        } else {
            $('body').css('overflow-y', 'scroll');
        }

        locatorModal.addClass(hiddenClassName).fadeOut(500);
        if (
            !(
                window.location.href.endsWith('our-craft') ||
                window.location.href.endsWith('our-craft/')
            )
        ) {
            $('#main-header-logo').removeClass('black-logo');
        }
        $('#main-header').css('position', '');
        $('#menu-toggle').removeClass('menu-toggle__close-button');
        if (document.documentElement.scrollTop > 74) {
            $('#main-header').addClass('sticky');
            $('#main-header-logo').addClass('black-logo');
        }
    }
};

function getCookie(cname) {
    var name = cname + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return '';
}

$(document).scroll(function () {
    checkOffset('.yacht-details-page__header-page--buttons__fixed');
});

function checkOffset(selector) {
    if (
        $(selector).offset()?.top >= $('.main-footer').offset()?.top
    ) {
        $(selector).css({
            visibility: 'hidden',
            pointerEvents: 'none'
        });
    }

    if (
        $(selector).offset()?.top < $('.main-footer').offset()?.top
    ) {
        $(selector).css({
            visibility: 'visible',
            pointerEvents: 'auto',
            position: 'fixed',
            bottom: 32,
            top: '',
        }); // restore when you scroll up
    }
}
