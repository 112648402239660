/* general styles */
import './general/scss/index.scss';

/* stuff and polyfills */
import './general/js/lazysizes';
//import './general/js/what-input';
/* components */
import './components/scroll-tracking';
import './components/lazysizes';
import './components/brochures';
import './components/language-selector';
import './components/tour-360';
import { clearPersonalisation } from './general/js/personalisationTracking';

/* legacy */
/* TODO: refactor legacy monocomponent in separate entry points*/
import './legacy/js/common';
import './legacy/js/components';
import './legacy/scss/app.scss';


/* require svg */
const files = require.context('./general/svg', true, /^\.\/.*\.svg/);
files.keys().forEach(files);

clearPersonalisation();