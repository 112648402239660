const scrollPercentages = {
    scroll50: false,
    scroll75: false,
    scroll100: false
};

export default class ScrollTracking {
    constructor() {
        this.init();
    }

    init() {
        window.dataLayer = window.dataLayer || [];
        this.addListeners();
    }

    addListeners() {
        document.addEventListener('scroll', this.onScroll.bind(this))
    }

    onScroll() {
        let percentage = ( ( document.documentElement.scrollTop  + window.innerHeight ) / document.documentElement.scrollHeight ) * 100;

        if(percentage > 50 && scrollPercentages.scroll50 === false){
            scrollPercentages.scroll50 = true;
            window.dataLayer.push({
                'event': 'siteengagement.click',
                'siteengagementType': 'scroll depth (50%+)',
                'siteengagementName': '50% ' + window.location.href
            });
        }
        if(percentage > 75 && scrollPercentages.scroll75 === false){
            scrollPercentages.scroll75 = true;
            window.dataLayer.push({
                'event': 'siteengagement.click',
                'siteengagementType': 'scroll depth (50%+)',
                'siteengagementName': '75% ' + window.location.href
            });
        }
        if(percentage === 100 && scrollPercentages.scroll100 === false){
            scrollPercentages.scroll100 = true;
            window.dataLayer.push({
                'event': 'siteengagement.click',
                'siteengagementType': 'scroll depth (50%+)',
                'siteengagementName': '100% ' + window.location.href
            });
        }
    }
}