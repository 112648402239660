import $ from 'jquery';

const toggleLineBreak = () => {
    if ($(window).width() < 1200) {
        $('.video-image .text-block__heading>br').hide();
    } else {
        $('.video-image .text-block__heading>br').show();
    }
};
window.onload = () => {
    toggleLineBreak();
};
$(document).ready(() => {
    toggleLineBreak();
});
$(window).on('load', toggleLineBreak);
$(window).resize(toggleLineBreak);
