import $ from 'jquery';

var animateHTML = function () {
    var elems, windowHeight;

    var init = function () {
        elems = document.getElementsByClassName('hidden-section');
        windowHeight = window.innerHeight;
        _addEventHandlers();
    };

    var _addEventHandlers = function () {
        window.addEventListener('load', () => {setTimeout(() => {_checkPosition()}, 500)});
        window.addEventListener('scroll', _checkPosition);
        window.addEventListener('resize', init);
    };
    var _checkPosition = function () {
        for (var i = 0; i < elems.length; i++) {
            var posFromTop = elems[i].getBoundingClientRect().top;
            if (posFromTop - windowHeight <= 0) {
                elems[i].className = elems[i].className.replace('hidden-section', 'animUp');
            }
        }
    };

    return {
        init: init,
    };
};

animateHTML().init();
$(document).on('load', () => {
    $('video').each(function () {
        let isPlaying = $(this)[0].currentTime > 0 && !$(this)[0].paused && !$(this)[0].ended && $(this)[0].readyState > 2;
        if ($(this).is(':in-viewport')) {
            if (!isPlaying) {
                $(this)[0].play().catch((e)=> {
                    console.error(e.message);
                });
            }
        } else {
            if (isPlaying) {
                $(this)[0].pause();
            }
        }
    });
});
