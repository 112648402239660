import './components/overlay-menu';
import Accordion from './components/accordion';
import './components/accessibility';
import './components/adroll';
import './components/animateOnScroll';
import './components/textImageVideo';
import './components/polyfill.custom-event';
import './components/parallax';
import { initVideoLightbox, initImageLightbox } from './components/our-craft-model-full-image';
import './components/locator';
import './components/home-banner';
import './components/custom-select';


if(document.querySelectorAll('.accordion').length) {
    const accordionEl = new Accordion();
    accordionEl.init();
}
initVideoLightbox();
initImageLightbox();
