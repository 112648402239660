import $ from 'jquery';
import Wave from '../vendors/wave';

let prevWidth = $(window).width();
$(window).on('load', () => {
    if ($(window).width() < 1024) {
        try {
            if (!$('.homepage .hero-banners-carousel').length) {
                return;
            }
            let hasScrolled = false;
            let lastY;
            let lastAnimationHeight = 100;
            let wavesHeight = 0;

            if($('#hero-banner-waves').length) {
                const waveAnimation = new Wave('#hero-banner-waves', {
                    number: 1,
                    smooth: 0,
                    velocity: 1,
                    height: 0,
                    colors: ['#fff'],
                    border: {
                        show: false,
                        width: 2,
                        color: [''],
                    },
                    opacity: 1,
                    position: 'bottom',
                });
            }
            const bannerVideo = $('.hero-banner>.inner>video');
            const transformVideo = () => {
                const valueToTranform = bannerVideo.height() - $('.hero-banners-carousel').height();
                bannerVideo.css({
                    transform: `translateY(-${valueToTranform}px)`,
                    'object-position': `50% ${valueToTranform}px`,
                });
            };

            const removeTransformVideo = () => {
                bannerVideo.css({
                    transform: 'translateY(0)',
                    'object-position': '50% 50%',
                });
            };

            $(window).on('scroll', function (e) {
                e.preventDefault();
                if ($(window).width() < 1024 && !hasScrolled) {
                    hasScrolled = true;

                    if(typeof waveAnimation !== 'undefined') {
                        waveAnimation.animate();
                        transformVideo();

                        setTimeout(function () {
                            waveAnimation.pause();
                        }, 2000);
                    }

                    calcHeroCarousel();
                }
            });
            $(window).on('resize', function (e) {
                if ($(window).width() !== prevWidth) {
                    const video = $('.hero-banner>.inner>video');
                    video.css({
                        height: '100vh',
                        'object-position': '50% 50%',
                        transform: 'none',
                    });
                    if ($(window).width() < 1024) {
                        hasScrolled = false;
                        video.css({
                            height: '100vh',
                            'object-position': '50% 50%',
                            transform: 'none',
                        });
                        initializeEvents();
                        calcHeroCarousel();
                        resetWaveHeightOptions();
                        if(typeof waveAnimation !== 'undefined') {
                            waveAnimation.setOptions({
                                height: 0,
                                smooth: 0,
                            });
                        }
                    } else {
                        initializeEvents();
                        calcHeroCarousel();
                        resetWaveHeightOptions();
                        removeTransformVideo();
                    }
                }
                prevWidth = $(window).width();
            });

            function calcHeroCarousel() {
                if ($(window).width() < 1024) {
                    let h =  $(window).height() * (60 / 100);
                    $('.hero-banners-carousel').css({ height: h });
                    $('.inner').css({ height: h });
                } else {
                    $('.hero-banners-carousel').css({ height: '' });
                    $('.inner').css({ height: '' });
                }
            }

            function resetWaveHeightOptions() {
                lastY = null;
                lastAnimationHeight = 100;
            }

            function initializeEvents() {
                if ($(window).width() < 1024) {
                    $('.hero-banners-carousel').on('touchend', function (e) {
                        if (lastAnimationHeight > 80) {
                            $('.hero-banners-carousel').css({
                                height: $(window).height() * 1
                            });
                            $('.inner').css({
                                height: $(window).height() * 1
                            });
                        } else {
                            if(typeof waveAnimation !== 'undefined') {
                                setTimeout(() => {
                                    setWaveOptions();
                                    setTimeout(function () {
                                        waveAnimation.pause();
                                    }, 2000);
                                }, 400);
                            }

                            $('.hero-banners-carousel').css({ height: $(window).height() * 0.6 });
                            $('.inner').css({ height: $(window).height() * 0.6 });

                            $('.hero-banners-carousel').off('touchmove');
                            $('.hero-banners-carousel').off('touchend');
                            // $(window).off('scroll');

                            if(typeof waveAnimation !== 'undefined') {
                                waveAnimation.animate();
                            }
                            transformVideo();
                            hasScrolled = true;
                        }
                    });

                    $('.hero-banners-carousel').on('touchmove', function (e) {
                        e.preventDefault();

                        $('.locator-open-icon').css({ bottom: 32, right: 32 });

                        const currentY = e.originalEvent.changedTouches[0].clientY;
                        if (currentY > lastY) {
                            // moved down
                            $('.hero-banners-carousel').css({
                                height: $(window).height() * (lastAnimationHeight / 100)
                            });
                            $('.inner').css({
                                height: $(window).height() * (lastAnimationHeight / 100)
                            });

                            if (lastAnimationHeight != 100) {
                                lastAnimationHeight += 10;
                            }
                        } else if (currentY < lastY) {
                            // moved up
                            $('.hero-banners-carousel').css({
                                height: $(window).height() * (lastAnimationHeight / 100)
                            });
                            $('.inner').css({
                                height: $(window).height() * (lastAnimationHeight / 100)
                            });

                            if (lastAnimationHeight > 60) {
                                lastAnimationHeight -= 10;
                            }
                        }

                        lastY = currentY;
                    });
                } else {
                    $('.hero-banners-carousel').off('touchmove');
                    $('.hero-banners-carousel').off('touchend');
                }
            }
            //calcHeroCarousel();
            initializeEvents();

            function setWaveOptions() {
                // waveAnimation.reset();

                waveAnimation.setOptions({
                    height: 60,
                    smooth: 30,
                });
                waveAnimation.animate();
            }

            // Wave animation
        } catch (e) {
            console.log('Error: ' + e);
        }
    }
});

if (window.location.pathname === '/' && window.screen.width <= 1024) {
    $(window).on('unload', function () {
        $(window).scrollTop(0);
    });

    $('body').css('overflow-y', 'hidden');

    const timer = setInterval(() => {
        if (document.readyState == 'complete') {
            $('body').css('overflow-y', 'scroll');
            clearInterval(timer);
        }
    }, 100);
}
