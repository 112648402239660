import $ from 'jquery';

$('.custom-select').each(function () {
    var classes = $(this).attr('class');
    var dataAction = $(this).attr('data-action');
    var backroundColor = $(this).attr('data-backround-color');
    var template = '<div class="' + classes + '" data-action="' + dataAction + '">';
    template += '<span style="background-color: ' + backroundColor +
        ' !important;" class="custom-select-trigger"><span class="select-header">' +
        $(this).attr('placeholder') + '</span></span>';
    template += '<div class="custom-options">';
    $(this).find('option').each(function () {
        const selectedClass = $(this).attr('selected') ? 'selection' : '';
        template += '<span style="background-color: ' + backroundColor +
            ' !important;" class="custom-option ' + $(this).attr('class') + ' ' + selectedClass +
            '" data-value="' + $(this).attr('value') +
            '">' + $(this).html() + '</span>';
    });
    template += '</div></div>';

    if ($(this).hasClass('custom-select__large')) {
        $(this).wrap('<div class="custom-select-wrapper__large"></div>');
    } else {
        $(this).wrap('<div class="custom-select-wrapper"></div>');
    }

    $(this).hide();
    $(this).after(template);
    addSelectedElement($(this).next());
});

$('.custom-option:first-of-type').hover(function () {
    $(this).parents('.custom-options').addClass('option-hover');
}, function () {
    $(this).parents('.custom-options').removeClass('option-hover');
});

$('.custom-select-trigger').on('click', function () {
    toggleLocatorSelect(this);
    event.stopPropagation();
});

$('.custom-select').on('keyup', function (event) {
    if (event.key === 'Enter') {
        const element = $(this).children()[0];
        toggleLocatorSelect(element);
        if (!$(event.target).hasClass('custom-option')) {
            event.stopPropagation();
        }
    }
});

const toggleLocatorSelect = function (element) {
    if (!$(element).parents('.custom-select').hasClass('opened')) {
        $('.custom-select.opened').removeClass('opened');
        $('.custom-select').find('.custom-select-trigger').css('border-bottom', '0px');
        $(element).parents('.custom-select').find('.custom-options').removeClass('active');
        $(element).parents('.custom-select').find('.custom-options').children().each(function () {
            $(this).attr('tabindex', -1);
        });
    }

    $(element).parents('.custom-select').toggleClass('opened');
    $(element).css('border-bottom', '1px solid rgba(112, 112, 112, 0.4)');
    $(element).parents('.custom-select').find('.custom-options').toggleClass('active');
    $(element).parents('.custom-select').find('.custom-options').children().each(function () {
        $(this).attr('tabindex', 0);
    });
    if (!$(element).parents('.custom-select').hasClass('opened')) {
        $(element).css('border-bottom', '0px');
    }

    const htmlHeight = $('html').height();
    const elementScrollPosition = $('.locator-modal__locator-section__first-dropdowns').offset().top;

    if (htmlHeight - elementScrollPosition <= 200) {
        $('.locator-modal__locator-section').animate(
            {
                scrollTop: 100
            },
            1000 //speed
        );
    }
};

const selectCustomOption = function (element) {
    $(element).parents('.custom-select-wrapper').find('select').val($(element).data('value'));
    $(element).parents('.custom-options').find('.custom-option').removeClass('selection');
    $(element).addClass('selection');
    $(element).parents('.custom-select').removeClass('opened');
    $(element).parents('.custom-select').find('.custom-select-trigger > span').text($(element).text());
};

$('.custom-option').on('click', function () {
    selectCustomOption(this);
});

$('.custom-option').on('keyup', function () {
    if (event.key === 'Enter') {
        selectCustomOption(this);
        //event.stopPropagation();
    }
});

function addSelectedElement(element) {
    const selectedOption = $(element[0]).find('.custom-options').find('.selection');
    $(element[0]).find('.custom-select-trigger').html(`<span class="select-header">${selectedOption.text()}</span>`);
    $(element[0]).find('.custom-select-wrapper').find('select').val(selectedOption.data('value'));
}

