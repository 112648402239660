const play = (element) => {
    const poster = element.getAttribute('data-poster');
    if (poster) {
        element.setAttribute('poster', poster);
        element.removeAttribute('data-poster');
    }
    element.setAttribute('autoplay', '');
    element.play();
};

[...document.querySelectorAll('video.lazyloaded')].forEach(play);

[...document.querySelectorAll('video.lazyload')]?.forEach((element) => {
    const onLoad = () => {
        element.removeEventListener('lazyloaded', onLoad);
        play(element);
    };
    element.addEventListener('lazyloaded', onLoad);
});
