import $ from 'jquery';

$.fn.faderTabs = function (options) {
    var opts = $.extend(
        {},
        {
            initIndex: 0,
            fadeDuration: 300,
            tabContentSelector: '.tab-content',
            tabContentItemsSelector: '.tab-content-item',
            tabNavItemsSelector: '.tab-nav-item',
            equaliseHeights: false,
        },
        options
    );

    function showTab(tabContentItems, tabNavItems, index, duration) {
        tabContentItems
            .eq(index)
            .not('.tab-content-active')
            .css({ opacity: 0, 'z-index': 2 })
            .stop(true)
            .animate({ opacity: 1 }, duration, function () {
                tabContentItems
                    .not(':eq(' + index + ')')
                    .removeClass('tab-content-active')
                    .css({ opacity: 0, 'z-index': 0 });
                tabContentItems.eq(index).addClass('tab-content-active').css({ 'z-index': 1 });
            });

        tabNavItems.removeClass('tab-nav-active').eq(index).addClass('tab-nav-active');
    }

    function equaliseHeights(tabContent, tabContentItems) {
        var height = 0;
        tabContentItems
            .add(tabContent)
            .css({ height: '' })
            .each(function () {
                if ($(this).outerHeight() > height) {
                    height = $(this).outerHeight();
                }
            })
            .css({ height: height.toString() + 'px' });
    }

    $(this).each(function () {
        var tabContent = $(opts.tabContentSelector, this);
        var tabContentItems = $(opts.tabContentItemsSelector, this);
        var tabNavItems = $(opts.tabNavItemsSelector, this);

        if (opts.equaliseHeights) {
            equaliseHeights(tabContent, tabContentItems);
            $(window).on('resize.faderTabs orientationchange.faderTabs', function () {
                equaliseHeights(tabContent, tabContentItems);
            });
        }

        tabNavItems.each(function (i) {
            $(this).on('click.faderTabs', function (e) {
                e.preventDefault();
                showTab(tabContentItems, tabNavItems, i, opts.fadeDuration);
            });
        });

        showTab(tabContentItems, tabNavItems, opts.initIndex, 0);
    });

    return $(this);
};
