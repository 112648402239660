import $ from 'jquery';
import 'slick-carousel';

$(window).on('change', () => {}, { passive: true });
$(window).on('load', () => {
    $('.tabbable').not('select').attr('tabindex', 0);

    $('.click-enter').on('keyup', function (event) {
        if (event.key === 'Enter') {
            event.preventDefault();
            if ($(this).hasClass('custom-select')) {
            } else {
                $(this).trigger('click');
            }
        }
    });

    const resetTabIndex = (selector) => {
        const closeBtn = $('#menu-toggle');
        let tabindex = 0;
        const ankers = $(selector).find('a, .tabbable');
        ankers.push(closeBtn);
        ankers.each(function () {
            if (tabindex == 0) {
                $(this).focus();
            }
            if ($(this).is(':visible')) {
                const $el = $(this);
                $el.attr('tabindex', tabindex);
            }
        });
        closeBtn.attr('tabindex', ankers.length);
    };
    const resetTabIndexSpecificationsModal = () => {
        const closeBtn = $('.yacht-details-page__specifications-modal--close__btn');
        const selector = '.yacht-details-page__specifications-modal';
        let tabindex = 0;
        let isFirst = true;
        let ankers = $(selector).find('a, .tabbable');
        const acordions = $(
            '.yacht-details-page__specifications-modal__info-box--content-carousel__acc-list--item'
        ).toArray();
        ankers.push(...acordions);
        ankers.push(closeBtn);
        ankers.each(function () {
            if (tabindex == 0 && isFirst) {
                $(this).focus();
                isFirst = false;
            }
            if ($(this).is(':visible')) {
                const $el = $(this);
                $el.attr('tabindex', tabindex);
            }
        });
        closeBtn.attr('tabindex', ankers.length);
    };

    const addTabToMenuAfterElement = (selector, menuSelector = '#menu-toggle') => {
        $(selector).on('focusout', () => {
            // Check for the locator modal
            if (
                $('.locator-modal__locator-section__distributor-dropdown__distributor-select')
                    .find('.custom-options')
                    .hasClass('active')
            ) {
                return;
            }

            const menuToggle = $(menuSelector);
            menuToggle.focus();
        });
    };
    $('#menu-toggle').on('click', function () {
        if ($(this).hasClass('menu-toggle__open')) {
            resetTabIndex('.navigation-modal');
        }
    });
    $('.specification-section__info__button').on('click', function () {
        resetTabIndexSpecificationsModal();
    });
    $('.locator-open-icon').on('click', function () {
        resetTabIndex('.locator-modal');
        openLocator();
    });

    addTabToMenuAfterElement($('.location .main-footer__navigation__link').get(1));
    addTabToMenuAfterElement(
        $('.locator-modal__locator-section__distributor-dropdown__distributor-select')
    );

    $('.yacht-details-page__specifications-modal__info-box--content-carousel__acc-list--item')
        .last()
        .on('focusout', () => {
            const closeBtn = $('.yacht-details-page__specifications-modal--close__btn');
            closeBtn.focus();
        });
});

$(window).on('load', () => {
    const size = $('html').css('font-size');
    if (+size.split('px')[0] > 16) {
        $('body').attr('class', 'zoomed-in-body');
        if (+size.split('px')[0] >= 32) {
            $('body').addClass('zoomed-in-two-hundred');
            $('.our-craft__category-section__filter-section__mobile-tablet__filter-button').off(
                'click'
            );
            $('.our-craft__category-section__filter-section__mobile-tablet__filter-button').on(
                'click',
                openFilterModal
            );
        }
    }
});

// Text resize library code
window.TextResizeDetector = (function () {
    var el = null;
    var iIntervalDelay = 200;
    var iInterval = null;
    var iCurrSize = -1;
    var iBase = -1;
    var aListeners = [];
    var createControlElement = function () {
        el = document.createElement('span');
        el.id = 'textResizeControl';
        el.innerHTML = '&nbsp;';
        el.style.position = 'absolute';
        el.style.left = '-9999px';
        var elC = document.querySelector(TextResizeDetector.TARGET_ELEMENT_ID);
        // insert before firstChild
        if (elC) elC.insertBefore(el, elC.firstChild);
        iBase = iCurrSize = TextResizeDetector.getSize();
    };

    function _stopDetector() {
        window.clearInterval(iInterval);
        iInterval = null;
    }
    function _startDetector() {
        if (!iInterval) {
            iInterval = window.setInterval('TextResizeDetector.detect()', iIntervalDelay);
        }
    }

    function _detect() {
        var iNewSize = TextResizeDetector.getSize();

        if (iNewSize !== iCurrSize) {
            for (var i = 0; i < aListeners.length; i++) {
                var aListnr = aListeners[i];
                var oArgs = {
                    iBase: iBase,
                    iDelta: iCurrSize != -1 ? iNewSize - iCurrSize + 'px' : '0px',
                    iSize: (iCurrSize = iNewSize),
                };
                if (!aListnr.obj) {
                    aListnr.fn('textSizeChanged', [oArgs]);
                } else {
                    aListnr.fn.apply(aListnr.obj, ['textSizeChanged', [oArgs]]);
                }
            }
        }
        return iCurrSize;
    }
    var onAvailable = function () {
        if (!TextResizeDetector.onAvailableCount_i) {
            TextResizeDetector.onAvailableCount_i = 0;
        }

        if (document.querySelector(TextResizeDetector.TARGET_ELEMENT_ID)) {
            TextResizeDetector.init();
            if (TextResizeDetector.USER_INIT_FUNC) {
                TextResizeDetector.USER_INIT_FUNC();
            }
            TextResizeDetector.onAvailableCount_i = null;
        } else {
            if (TextResizeDetector.onAvailableCount_i < 600) {
                TextResizeDetector.onAvailableCount_i++;
                window.setTimeout(onAvailable, 200);
            }
        }
    };
    window.setTimeout(onAvailable, 500);

    return {
        /*
         * Initializes the detector
         *
         * @param {String} sId The id of the element in which to create the control element
         */
        init: function () {
            createControlElement();
            _startDetector();
        },
        /**
         * Adds listeners to the ontextsizechange event.
         * Returns the base font size
         *
         */
        addEventListener: function (fn, obj, bScope) {
            aListeners[aListeners.length] = {
                fn: fn,
                obj: obj,
            };
            return iBase;
        },
        /**
         * performs the detection and fires textSizeChanged event
         * @return the current font size
         * @type {integer}
         */
        detect: function () {
            return _detect();
        },
        /**
         * Returns the height of the control element
         *
         * @return the current height of control element
         * @type {integer}
         */
        getSize: function () {
            var iSize;
            return el.offsetHeight;
        },
        /**
         * Stops the detector
         */
        stopDetector: function () {
            return _stopDetector();
        },
        /*
         * Starts the detector
         */
        startDetector: function () {
            return _startDetector();
        },
    };
})();

TextResizeDetector.TARGET_ELEMENT_ID = 'doc';
TextResizeDetector.USER_INIT_FUNC = null;

function init() {
    var iBase = TextResizeDetector.addEventListener(onFontResize, null);
}
function onFontResize(e, args) {
    if (args[0].iSize > 18) {
        $('body').addClass('zoomed-in-body');

        if (args[0].iSize >= 38) {
            $('body').addClass('zoomed-in-two-hundred');
            $('.our-craft__category-section__filter-section__mobile-tablet__filter-button').off(
                'click'
            );
            $('.our-craft__category-section__filter-section__mobile-tablet__filter-button').on(
                'click',
                openFilterModal
            );
        }
    } else {
        $('body').removeClass('zoomed-in-body');

        if (args[0].iSize < 38) {
            $('body').removeClass('zoomed-in-two-hundred');
            $('.our-craft__category-section__filter-section__mobile-tablet__filter-button').off(
                'click'
            );
        }
    }
}
//id of element to check for and insert control
TextResizeDetector.TARGET_ELEMENT_ID = 'html';
//function to call once TextResizeDetector has init'd
TextResizeDetector.USER_INIT_FUNC = init;
