import $ from 'jquery';

function isCurrentBrowserChrome() {
    const isChromium = window.chrome;
    const winNav = window.navigator;
    const vendorName = winNav.vendor;
    const isOpera = typeof window.opr !== 'undefined';
    const isIEedge = winNav.userAgent.indexOf('Edge') > -1;
    const isIOSChrome = winNav.userAgent.match('CriOS');

    if (isIOSChrome) {
        return true;
    } else if (
        isChromium !== null &&
        typeof isChromium !== 'undefined' &&
        vendorName === 'Google Inc.' &&
        isOpera === false &&
        isIEedge === false
    ) {
        return true;
    } else {
        return false;
    }
}

$('.active-class-item').hide();

window.addEventListener('scroll', scrollFunction);
window.toggleOpenMenuIcon = function (element) {
    $($('#menu-toggle')).removeClass('menu-toggle__close__hover');
    $($('#menu-toggle')).removeClass('menu-toggle__open__blue__hover');
    $($('.active-class-item')[0]).css('display', 'flex');

    if ($(element).hasClass('menu-toggle__close-button')) {
        const isLocatorOpen = !$(`.locator__main-container`).hasClass(
            'locator__main-container__hidden'
        );
        const isOurCraftFilterOpen = !$('.our-craft-filter-modal').hasClass(
            'our-craft-filter-modal__hidden'
        );

        if (isLocatorOpen) {
            openLocator();
        }

        if (isOurCraftFilterOpen) {
            $('.js-open-filter-modal').click();
        }
    } else {
        const isOpen = element.getAttribute('x-open');
        const isChrome = isCurrentBrowserChrome();
        const icon = document.querySelector('.locator-open-icon');
        if (isOpen === 'true') {
            rotateToggleIcon(true);
            if (
                window.location.href.endsWith('our-craft') ||
                window.location.href.endsWith('our-craft/')
            ) {
                if (!(document.body.scrollTop > 80 || document.documentElement.scrollTop > 80)) {
                    element.classList.add('menu-toggle__open__blue');
                }
            } else {
                $('#main-header-logo').removeClass('black-logo');
            }

            if (isChrome) {
                $('body').css('overflow-y', 'overlay');
            } else {
                $('body').css('overflow-y', 'auto');
                $('#main-header').css('padding-right', '0');
            }

            $('.navigation-modal').fadeOut(300);
            element.classList.remove('menu-toggle__open');
            element.classList.add('menu-toggle__close');
            element.setAttribute('x-open', 'false');

            if (icon) icon.style.display = 'block';
        } else {
            rotateToggleIcon(false);
            element.classList.remove('menu-toggle__open__blue');
            $('body').css('overflow-y', 'hidden');
            if (!isChrome) {
                $('#main-header').css('padding-right', '15px');
            }

            $('.navigation-modal').removeClass('hidden').fadeIn(300);
            element.classList.remove('menu-toggle__close');
            element.classList.add('menu-toggle__open');
            element.setAttribute('x-open', 'true');
            if (icon) icon.style.display = 'none';
            $('#main-header-logo').addClass('black-logo');
            $('body').css('position', 'relative');
        }
    }
};

window.toggleActiveItem = function (element) {
    $('.active-class-item').hide();
    document.querySelector(
        "[data-x-model='" + element.getAttribute('data-model-class') + "']"
    ).style.display = 'flex';
};

window.openSubNavMenu = function (element) {
    $('#header-sub-nav').attr('style', 'display: block !important');
    $('#header-nav').attr('style', 'display: none !important');
    document
        .querySelectorAll("[name='menu-sub-navigation']")
        .forEach((x) => (x.style.display = 'none'));

    //show specific block only by parent id
    const parentId = element.getAttribute('data-id');

    document.querySelector("[data-parent-id='" + parentId + "']").style.display = 'block';
    //todo - show first element style.display = 'block
};

window.closeSubNavMenu = function () {
    $('#header-nav').attr('style', 'display: block !important');
    $('#header-sub-nav').attr('style', 'display: none !important');
    document
        .querySelectorAll("[name='menu-sub-navigation']")
        .forEach((x) => (x.style.display = 'none'));
};

window.showLatestEventsSection = function () {
    $('#header-navigation').addClass('is-mobile-hidden');
    $('#header-info-section').removeClass('is-mobile-hidden');
};

window.goToMainNav = function () {
    $('#header-navigation').removeClass('is-mobile-hidden');
    $('#header-info-section').addClass('is-mobile-hidden');
};

function rotateToggleIcon(toggleIsOpen) {
    const toggleMenu = document.getElementById('menu-toggle');
    if ($('#localisation-selection').css('display') == 'block') {
        return;
    }

    toggleIsOpen = !toggleIsOpen;
    if (toggleIsOpen) {
        toggleMenu.style.transform = 'rotate(90deg)';
    } else {
        toggleMenu.style.transform = 'rotate(0deg)';
    }
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
        if (toggleIsOpen) {
            toggleMenu.style.transform = 'rotate(90deg)';
        } else {
            toggleMenu.style.transform = 'rotate(0deg)';
        }
    }
}

//Rotating

const toggleMenu = document.getElementById('menu-toggle');
if (toggleMenu) {
    toggleMenu.style.transition = 'none';
    toggleMenu.style.transform = 'rotate(0)';
    toggleMenu.style.transition = 'transform 0.8s';
}

function initVideoPlayBadge() {

    $('.video-container-manual-play').each(function(index, el) {
        // set the variables
        let timer;
        let mouseX = 0,
            mouseY = 0;
        let xp = 0,
            yp = 0;
        let cursorImg;
        let badge = $(el).find('.video-badge');

        if($(el).attr('data-cursor')) {
            cursorImg = $(el).attr('data-cursor');
            $(el).find('.video-cursor span').css('background-image', 'url(' + cursorImg + ')');
        }

        // set the momentum with setInterval function
        const loop = function () {
            // change 12 to alter damping higher is slower
            xp += (mouseX - xp) / 6;
            yp += (mouseY - yp) / 6;
            badge.css({ left: xp + 'px', top: yp + 'px' });
        };

        $(el)
            .mouseover(function (e) {
                const ieValueInterval = 10;
                const otherBrowsersValueInterval = 20;
                const isIe =
                    navigator.userAgent.indexOf('MSIE ') > -1 ||
                    navigator.userAgent.indexOf('Trident/') > -1;
                if (isIe) {
                    timer = setInterval(loop, ieValueInterval);
                } else {
                    timer = setInterval(loop, otherBrowsersValueInterval);
                }
            })
            .mousemove(function (e) {
                // get the mouse position minus badge to center the circle
                mouseX = e.offsetX;
                mouseY = e.offsetY;
            })
            .mouseleave(function (e) {
                clearInterval(timer);
                badge.clearQueue();
                badge.animate({ left: '50%', top: '50%' }, 750);
            });
    });
}

initVideoPlayBadge();

function scrollFunction() {
    try {
        const logo = document.getElementById('menu-toggle');
        const crumbs = document.getElementsByClassName('main-header__breadcrumbs')[0];

        if (document.documentElement.scrollTop > 74) {
            logo.classList.remove('menu-toggle__open__blue');
        } else {
            if (
                window.location.href.endsWith('our-craft') ||
                window.location.href.endsWith('our-craft/')
            ) {
                logo.classList.add('menu-toggle__open__blue');
            } else {
                logo.classList.add('menu-toggle__close');
            }
        }

        if (document.documentElement.scrollTop > 160) {
            crumbs.style.display = 'flex';
        } else {
            crumbs.style.display = 'none';
        }
    } catch (error) {}
}

$('#menu-toggle').on('mouseover focus', function () {
    if ($('#main-header').hasClass('sticky')) {
        return;
    }

    if (!$('#header-nav').hasClass('hidden')) {
        return;
    }

    if ($(this).hasClass('menu-toggle__open__blue')) {
        $(this).addClass('menu-toggle__open__blue__hover');
    } else if ($(this).hasClass('menu-toggle__close')) {
        $(this).addClass('menu-toggle__close__hover');
    }
});

$('#menu-toggle').on('mouseleave focusout', function () {
    $(this).removeClass('menu-toggle__close__hover');
    $(this).removeClass('menu-toggle__open__blue__hover');
});

const menuToggle = $('#menu-toggle');
const header = $('#main-header');
$(window).on('scroll', (e) => {
    const posToStick = menuToggle.offset()?.top + menuToggle.height();
    const isSticky = header.hasClass('sticky');
    if ($(window).scrollTop() >= 74 && !isSticky) {
        header.addClass('sticky');
    } else if ($(window).scrollTop() < 74 && isSticky) {
        header.removeClass('sticky');
    }
});
