import $ from 'jquery';

export default class Brochures {
    constructor() {
        this.$element = $('.js-brochures');
        
        this.$links = this.$element.find('.js-switch-link');
        this.$cards = this.$element.find('.js-brochure-card');

        this.init();
    }

    init() {
        this._addListeners();
    }

    _addListeners() {
        this.$links.on('click', this._onOptionClick.bind(this));
    }


    _onOptionClick(e) {
        const $el = $(e.target);
        this.$links.removeClass('is-active');
        $el.addClass('is-active');
        this.filterBrochures($el.data('value'));
    }

    filterBrochures(value) {
        const lng = value;
        this.$cards.each(function(){
            const url = $(this).attr('data-url-' + lng);
            if (!url) {
                $(this).removeClass('is-visible');
            } else {
                $(this).find('.js-brochure-link')[0].href = url;
                $(this).addClass('is-visible');
            }
        });
    }
}
