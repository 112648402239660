import $ from 'jquery';
import {trackPersonalisation} from 'general/js/personalisationTracking';

export default class LanguageSelector {
    constructor() {
        this.$elements = $('.js-language-selector');
        this.init();
    }

    init() {
        this.$elements.each(function () {
            var classes = $(this).attr('class');
            var dataAction = $(this).attr('data-action');
            var template = '<div class="custom-language-selector" data-action="' + dataAction + '">';
            template += '<span class="custom-language-selector__trigger"><span class="custom-language-selector__header">' +
                $(this).find('option:selected').text() + '</span></span>';
            template += '<div class="custom-language-selector__options">';
            $(this).find('option').each(function () {
                const selectedClass = $(this).attr('selected') ? 'is-selected' : '';
                template += '<span class="custom-language-selector__option ' +
                    selectedClass + '" data-value="' + $(this).attr('value') +
                    '">' + $(this).html() + '</span>';
            });
            template += '</div></div>';

            $(this).wrap('<div class="custom-language-selector__wrapper"></div>');
            $(this).hide();
            $(this).after(template);
        });

        this._addListeners();
    }

    _addListeners() {
        this.$elements.parents('.custom-language-selector__wrapper').find('.custom-language-selector__trigger').on('click', this._onOpenSelector.bind(this));
        this.$elements.parents('.custom-language-selector__wrapper').find('.custom-language-selector__option').on('click', this._onOptionClick.bind(this));
    }

    _onOptionClick(e) {
        if (window.ga !== undefined) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'siteengagement.click',
                'siteengagementType': 'language selection',
                'siteengagementName': $(e.target).text().trim()
            });
        }
        trackPersonalisation($(e.target).text().trim().toUpperCase());
        window.location.href = $(e.target).data('value');
    }

    _onOpenSelector(e) {
        let $el = $(e.target).parents('.custom-language-selector__wrapper').find('.custom-language-selector');
        if (!$el.hasClass('is-opened')) {
            $el.removeClass('is-opened');
            $el.find('.custom-language-selector__option').removeClass('is-active');
        }

        $el.toggleClass('is-opened');
        $el.find('.custom-language-selector__option').toggleClass('is-active');
        e.stopPropagation();
    }
}
