import $ from 'jquery';
import './vendors/jquery-ui';
import './vendors/jquery.cookie';
import './vendors/jquery.scrollTo.custom';
import './vendors/foundation/foundation';
import 'slick-carousel';
import './jquery-modifiers/jquery.faderTabs';
import './jquery-modifiers/jquery.easing.1.3';
import './jquery-modifiers/jquery.localisationSelection';
import Interactor from './vendors/interactor';
import whatInput from 'what-input';
import {trackVideo} from 'general/js/videoTrackingService';

const SMALL = 640;
const MEDIUM = 1024;
const LARGE = 1440;
const isBigger = (size) => window.matchMedia(`only screen and (min-width:${size}px)`).matches;
const isSmaller = (size) => window.matchMedia(`only screen and (max-width:${size}px)`).matches;
const isBetween = (from, to) =>
    window.matchMedia(`only screen and (min-width:${from}px) and (max-width:${to}px)`).matches;

const getDeviceState = () => ({
    isSmallDevice: isSmaller(SMALL),
    isMediumDevice: isBetween(SMALL, MEDIUM),
    isLargeDevice: isBetween(MEDIUM, LARGE),
    isXLargeDevice: isBigger(LARGE),
    isPortraitOrientation: window.matchMedia('only screen and (orientation:portrait)').matches,
});

try {
    Typekit.load();
} catch (e) {}

var safari =
    navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') === -1;

window.state = getDeviceState();

var rtl = $('html').is('[dir="rtl"]');

var sDuration = state.isLargeDevice ? 1000 : 500;
var sHeaderHeight = state.isSmallDevice ? 70 : 100;
var sOffset =
    state.isSmallDevice && $('.section-navigation').length > 0
        ? -176
        : state.isLargeDevice
        ? -118
        : -106;
var sNavUpdateOnScroll = true;

var localisationInitialised = false;
window.googleMapsApiReady = false;
var grecaptchaReady = false;

function initLocalisation() {
    if (!localisationInitialised) {
        localisationInitialised = true;

        $('#localisation-selection, #localisation-selection-modal').localisationSelection({
            sDuration: sDuration,
            onToggleModal: function (modalVisible) {
                var event = new CustomEvent('localisationSelectionToggleModal', {
                    detail: modalVisible,
                });
                window.dispatchEvent(event);
            },
        });
    }
}

window.onGoogleMapsApiReady = () => {
    window.googleMapsApiReady = true;
    initLocalisation();
}

var lTimeout = setTimeout(initLocalisation, 5000);

$(function () {
    /* Global variables *******************************************************/

    var scrollTop = $(window).scrollTop();
    var windowWidth = $(window).width();
    var windowHeight = $(window).height();
    var contentTop = $('.content-top').offset()?.top;

    var hasHeroBanner = $('.hero-banner').length > 0 || $('.yacht-details-page').length > 0;
    var hasHeroBannerVideo = $('.hero-banner-video').length > 0;

    // Extra padding on pages without banners
    if (!hasHeroBanner) {
        $('.page-layout').addClass('no-hero-banner');
    }

    if (hasHeroBannerVideo) {
        $('.page-layout').addClass('has-hero-banner-video');
    }

    if ($('.hero-banner:first-child').hasClass('darktheme')) {
        $('.content-top-scrolldown, .main-header').addClass('darktheme');
    }

    /* Navigation & header ****************************************************/

    var sLocation;
    // set the properties of the sLocation (section location) object
    // which defines the target section
    function setSectionLocation(hash) {

        // return value of the hash parameter if defined,
        // or the value of the hash from the window.location object (the URL) if not
        return { hash: $.type(hash) != 'undefined' ? hash : location.hash };
    }

    // Update the state and position of the section navigation
    function updateSectionNavigation(hash) {

        // set current state on appropriate section navigation element
        $('#section-navigation .nav-item > a').removeClass('current visible');

        if (hash !== undefined) {
            sLocation = setSectionLocation(hash);
            $('#section-navigation a[href="' + sLocation.hash + '"]').addClass('current');
        } else if (sNavUpdateOnScroll) {
            $('#section-navigation .nav-item > a').each(function (e) {
                var sectionSelector = $(this).attr('href');
                if (
                    $(sectionSelector).length > 0 &&
                    scrollTop > $(sectionSelector).offset().top + sOffset - 30
                ) {
                    $(this).addClass('visible');
                }
            });
            $('#section-navigation a.visible:last').addClass('current');
        }

        var sectionNavigation = $('.section-navigation');
        if (sectionNavigation.length > 0) {
            var threshold = sectionNavigation.offset().top - 70;
            if (threshold < sHeaderHeight) threshold = sHeaderHeight;
            if (scrollTop > threshold || $('body').attr('data-pos') > 70) {
                sectionNavigation.addClass('sticky');
            } else {
                sectionNavigation.removeClass('sticky');
            }
        }
    }

    function updateStickyScrollDown() {
        var offset = $('.content-top-scrolldown').is('.has-count') ? 22 : 0;
        if (contentTop > windowHeight + scrollTop + offset) {
            $('.content-top-scrolldown').addClass('sticky');
        } else {
            $('.content-top-scrolldown').removeClass('sticky');
        }
    }

    function initNavigation() {
        var navigationModal = $('.navigation-modal').addClass('hidden').hide();

        var sectionNavigation = $('.section-navigation');

        sectionNavigation
            .not('#secondary-navigation')
            .find('a[data-target-url-name]')
            .each(function () {
                var href = '#' + $(this).attr('data-target-url-name');
                $(this).attr('href', href);
                if ($(href).length === 0) $(this).closest('.nav-item').hide();
            });

        sectionNavigation
            .not('#secondary-navigation')
            .find('a[data-target-url-name]')
            .each(function () {
                var href = '#' + $(this).attr('data-target-url-name');
                $(this).attr('href', href);
            });

        $('a[data-action="toggle-filter-groups"], .nav-trigger', sectionNavigation).on(
            'click',
            function (e) {
                e.preventDefault();
                $('.nav-menu', sectionNavigation).toggleClass('nav-menu-active');
            }
        );

        $('.nav-item > a[href^="#"]', sectionNavigation)
            .on('click', function (e) {
                e.preventDefault();
                $('.nav-menu', sectionNavigation).removeClass('nav-menu-active');
            })
            .each(function () {
                var href = $(this).attr('href');
                if ($(href).length === 0) $(this).closest('.nav-item').hide();
            });

        $('a[href^="#"]:not(a[href="#"], a[href="#enquire"])').on('click', function (e) {
            e.preventDefault();
            sNavUpdateOnScroll = false;
            var hash = $(this).trigger('blur').attr('href');
            if($(hash).length) $('html, body').animate({scrollTop: $(hash).offset().top}, 300);

            updateSectionNavigation(hash);
            sNavUpdateOnScroll = true;
        });
        
        updateSectionNavigation();
        updateStickyScrollDown();
    }

    sLocation = setSectionLocation();

    if (sLocation.hash != '') {
        setTimeout(function () {
            $.scrollTo(sLocation.hash, {
                duration: 0,
                offset: sOffset,
                onAfter: function () {
                    initNavigation();
                },
            });
        }, 100);
    } else {
        initNavigation();
    }

    /* Craft category & navigation ********************************************/

    $('[data-navigate-to-filter-group]').each(function () {
        var craftCategoryStateCookie = $.cookie('pyCraftCategoryState');
        if (craftCategoryStateCookie !== undefined) {
            var craftCategoryState = JSON.parse(craftCategoryStateCookie);
            if (
                craftCategoryState.filterGroup !== undefined &&
                craftCategoryState.filterGroup === $(this).attr('data-navigate-to-filter-group')
            ) {
                $(this).attr('href', craftCategoryState.href);
            }
        }
    });

    /* Slick carousels ********************************************************/

    // initCarousels called on initLazyLoadedImages callback
    function initCarousels() {
        var heroBanners = $('#hero-banners-carousel');
        if (heroBanners.length > 0) {
            var heroBannersTimeout;
            var firstHeroBanner = $('.hero-banner:first');
            heroBanners.toggleClass('darktheme', firstHeroBanner.is('.darktheme'));
            // Do carousel if more than one hero banner
            if ($('.hero-banner').length > 1) {
                $('.content-top-scrolldown').addClass('has-count');
                // Autoplay carousel if property set,
                // but not if the first banner is an autoplay video
                var autoplay =
                    heroBanners.attr('data-autoplay-carousel') === 'true' &&
                    !$('.hero-banner-video', firstHeroBanner).is('[data-autoplay="true"]');
                heroBanners
                    .on('init', function (e, slick) {
                        // Play first banner's autoplay video
                        var autoplayVideo = $('.video[data-autoplay="true"]', firstHeroBanner);
                        if (autoplayVideo.length > 0) playVideo(autoplayVideo, 0);
                        // Pause carousel autoplay if any video is playing,
                        // because carousel change will pause any playing video (see 'afterChange' below)
                        window.addEventListener('playingVideo', function (e) {
                            slick.slickPause();
                        });
                    })
                    .removeClass('no-slick')
                    .find('.herobanners')
                    .slick({
                        adaptiveHeight: true,
                        arrows: false,
                        dots: false,
                        infinite: true,
                        itemSelector: '.hero-banner',
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        speed: 900,
                        cssEase: 'ease',
                        autoplay: autoplay,
                        autoplaySpeed: 5000,
                        pauseOnHover: false,
                        rtl: rtl,
                    })
                    .on('beforeChange', function (e, slick, currentSlide, nextSlide) {
                        var nextHeroBanner = $(
                            '.hero-banner[data-slick-index="' + nextSlide + '"]'
                        );
                        clearTimeout(heroBannersTimeout);
                        heroBannersTimeout = setTimeout(function () {
                            heroBanners
                                .add('.content-top-scrolldown, .main-header')
                                .toggleClass('darktheme', nextHeroBanner.is('.darktheme'));
                        }, 500);
                    })
                    .on('afterChange', function (e, slick, currentSlide) {
                        var currentHeroBanner = $(
                            '.hero-banner[data-slick-index="' + currentSlide + '"]'
                        );
                        var autoplayVideo = $('.video[data-autoplay="true"]', currentHeroBanner);
                        // If banner has autoplay video, play it (playing any video will pause all other playing videos)
                        // Otherwise, just pause all other playing videos
                        autoplayVideo.length > 0
                            ? playVideo(autoplayVideo, 0)
                            : pausePlayingVideo();
                        // Recalculate content top and scroll down button position
                        // in case height has changed
                        setTimeout(function () {
                            scrollTop = $(window).scrollTop();
                            contentTop = $('.content-top').offset().top;
                            updateStickyScrollDown();
                        }, 100);
                    });
            } // Only one hero banner
            else {
                // Play banner's autoplay video
                var autoplayVideo = $('.video[data-autoplay="true"]', firstHeroBanner);
                if (autoplayVideo.length > 0) playVideo(autoplayVideo, 0);
            }
        }

        if($('.media-video__list').length) {
            import('./jquery-modifiers/jquery.slickCounter')
                .then(() => {
                    $('.media-video__list')
                        .slick({
                            adaptiveHeight: false,
                            arrows: true,
                            dots: false,
                            infinite: false,
                            itemSelector: '.media-video__item',
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            rtl: rtl,
                        })
                        .on('afterChange', function (e, slick, currentSlide) {
                            pausePlayingVideo();
                        })
                        .slickCounter();
                });
        }

        if($('.grid-carousel .grid-panels__list').length) {
            import('./jquery-modifiers/jquery.slickCounter')
                .then(() => {
                    $('.grid-carousel .grid-panels__list').on('init', function(event, slick) {
                        slick.$nextArrow.on('click', () => {
                            console.log('next slide');
                            if (window.ga !== undefined) {
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event': 'siteengagement.click',
                                    'siteengagementType': 'links clicked',
                                    'siteengagementName': 'Next slide'
                                });
                            }
                        });
                        slick.$prevArrow.on('click', () => {
                            if (window.ga !== undefined) {
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event': 'siteengagement.click',
                                    'siteengagementType': 'links clicked',
                                    'siteengagementName': 'Previous slide'
                                });
                            }
                        });
                    });
                    $('.grid-carousel .grid-panels__list')
                        .slick({
                            adaptiveHeight: false,
                            arrows: true,
                            dots: false,
                            infinite: true,
                            itemSelector: '.grid-panel-item',
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            rtl: rtl,
                        })
                        .slickCounter();
                });
        }

        // Personnel
        if($('.distributor-personnel__carousel').length) {
            import('./jquery-modifiers/jquery.slickCounter')
                .then(() => {
                    $('.distributor-personnel__carousel').on('init', function(event, slick) {
                        slick.$nextArrow.on('click', () => {
                            console.log('next slide');
                            if (window.ga !== undefined) {
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event': 'siteengagement.click',
                                    'siteengagementType': 'links clicked',
                                    'siteengagementName': 'Next slide'
                                });
                            }
                        });
                        slick.$prevArrow.on('click', () => {
                            if (window.ga !== undefined) {
                                window.dataLayer = window.dataLayer || [];
                                window.dataLayer.push({
                                    'event': 'siteengagement.click',
                                    'siteengagementType': 'links clicked',
                                    'siteengagementName': 'Previous slide'
                                });
                            }
                        });
                    });
                    $('.distributor-personnel__carousel')
                        .slick({
                            adaptiveHeight: true,
                            arrows: true,
                            dots: false,
                            infinite: false,
                            itemSelector: '.personnel-post',
                            slidesToShow: 1,
                            slidesToScroll: 1,
                            rtl: rtl,
                            mobileFirst: true,
                            responsive: [
                                {
                                    breakpoint: 720,
                                    settings: {
                                        slidesToShow: 2,
                                        slidesToScroll: 2,
                                    },
                                },
                                {
                                    breakpoint: 960,
                                    settings: {
                                        slidesToShow: 3,
                                        slidesToScroll: 3,
                                    },
                                },
                            ],
                        })
                        .slickCounter();
                });
        }
    }

    /* Craft Model Galleries **************************************************/

    function initGallerySlick(galleryImages, galleryThumbnails, initialSlide) {
        var galleryPortraitOrientation =
            (state.isSmallDevice && state.isPortraitOrientation) || state.isMediumDevice;

        galleryImages.slick({
            asNavFor: '#' + galleryThumbnails.attr('id'),
            arrows: false,
            dots: false,
            initialSlide: initialSlide,
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: 500,
            rtl: rtl,
        });

        galleryThumbnails.slick({
            asNavFor: '#' + galleryImages.attr('id'),
            arrows: true,
            dots: false,
            focusOnSelect: true,
            initialSlide: initialSlide,
            slidesToShow: galleryPortraitOrientation ? 4 : 5,
            slidesToScroll: 1,
            swipeToSlide: true,
            speed: 500,
            rtl: galleryPortraitOrientation ? rtl : false,
            vertical: !galleryPortraitOrientation,
            verticalSwiping: true,
        });
    }

    function updateMediaGalleries() {
        $(
            '.gallery-images-container.slick-initialized'
        ).each(function (i, element) {
            var currentSlide = $(element).slick('slickCurrentSlide');
            var galleryImages = $(element).slick('unslick');
            var galleryThumbnails = $(element)
                .parent()
                .find('.gallery-thumbnails')
                .slick('unslick');
            initGallerySlick(galleryImages, galleryThumbnails, currentSlide);
        });
    }

    // initMediaGalleries called on initLazyLoadedImages callback
    function initMediaGalleries() {
        $('.media-galleries').faderTabs({
            tabContentSelector: '.media-galleries__content',
            tabContentItemsSelector: '.media-gallery',
            tabNavItemsSelector: '.media-galleries__nav-item',
        });

        $('.gallery-images-container')
            .not('.slick-initialized')
            .each(function (i, element) {
                var galleryImages = $(element);
                var galleryThumbnails = $(element).parent().find('.gallery-thumbnails');
                initGallerySlick(galleryImages, galleryThumbnails, 0);
            });
    }

    /***********************************************************************************************/

    // Service Accordion
    $(document).on('click', '.distributor-service-heading', function (e) {
        e.preventDefault();
        var _this = $(this);
        $(this)
            .next('.distributor-service')
            .slideToggle(function () {
                _this.trigger('blur').toggleClass('active');
            });
    });

    // Personnel posts
    $(document).on('click', '.personnel-post__toggle', function (e) {
        e.preventDefault();
        $(this).next('.personnel-post__body-text').slideToggle();
        $(this).trigger('blur');
    });

    /* Videos *****************************************************************/

    function pausePlayingVideo() {
        // Vimeo
        $.each(vimeoPlayers, function (i, player) {
            player.pause();
        });
    }

    /* Videos - Vimeo SDK *****************************************************/

    var vimeoPlayers = [];

    $('.video-player[data-vimeo-video-id]').not('.mfp-hide .video-player').each(function () {
        var options = {
            id: $(this).attr('data-vimeo-video-id'),
            width: $(this).width(),
            maxheight: $(this).height(),
            title: false,
            byline: false,
            portrait: false,
            loop: $(this).attr('data-vimeo-video-loop') === 'true',
            muted: $(this).attr('data-vimeo-video-muted') === 'true',
            autoplay: $(this).attr('data-vimeo-video-autoplay') === 'true',
        };

        // DON'T attempt autoplay unless muted
        if (!options.muted) {
            options.autoplay = false;
        }

        // DON'T hide controls unless autoplay and muted!
        // Can't play without controls if autoplay prevented by browser
        // If autoplay true, user can't pause without controls
        if (options.autoplay && options.muted) {
            options.controls = $(this).attr('data-vimeo-video-controls') === 'true';
        } else {
            options.controls = true;
        }

        var player = new Vimeo.Player(this, options);
        vimeoPlayers.push(player);

        // don't track background videos
        if(!options.autoplay || options.controls) {
            trackVideo(player);
        }
    });

    /* Recruitment links ********************************************************/

    $('[data-recruitment-event]').on('click', function (e) {
        var eventCategory = $(this).attr('data-event-category');
        var eventAction = $(this).attr('data-event-action');
        var eventLabel = $(this).attr('data-event-label');

        // Google Analytics event tracking
        if (window.ga !== undefined) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': eventCategory,
                'recruitmentleadType': eventAction,
                'recruitmentleadName': eventLabel,
                'recruitmentjobPost': $('h1').text()
            });
        }
    });

    $('.nav-list-social-icon').on('click', function(e) {
        if(e.currentTarget.href) {
            if (window.ga !== undefined) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'siteengagement.click',
                    'siteengagementType': 'social links clicked',
                    'siteengagementName': e.currentTarget.href
                });
            }
        }
    });

    setTimeout(() => {

        $('.recruitment-content-section a').on('click', function(e) {
            if(e.target.href && e.target.href.startsWith('mailto:')) {
                if (window.ga !== undefined) {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'recruitmentleadgeneration.click',
                        'recruitmentleadType': 'actions to apply for the post',
                        'recruitmentleadName': e.target.href.replace('mailto:',''),
                        'recruitmentjobPost': $('h1').text()
                    });
                }
            }
        });

        $('a[download]').on('click', function(e) {
            if (window.ga !== undefined) {
                window.dataLayer = window.dataLayer || [];
                window.dataLayer.push({
                    'event': 'siteengagement.click',
                    'siteengagementType': 'downloads',
                    'siteengagementName': e.currentTarget.href
                });
            }
        });

        $('a:not([download]):not([data-contact-location-event]):not([data-recruitment-event]):not(.nav-list-social-icon)').on('click', function(e) {
            if($(e.currentTarget).parents('.recruitment-content-section').length) return;

            let eventLabel = $(e.currentTarget).attr('data-event-label') ? $(e.currentTarget).attr('data-event-label') : $(e.currentTarget).text().trim();

            if (window.ga !== undefined) {
                window.dataLayer = window.dataLayer || [];
                if (typeof $(e.currentTarget).attr('target') && $(e.currentTarget).attr('target') == '_blank' && window.location.host != e.currentTarget.hostname) {
                    window.dataLayer.push({
                        'event': 'siteengagement.click',
                        'siteengagementType': 'links clicked',
                        'siteengagementName': eventLabel + ' - ' + e.currentTarget.href
                    });
                } else {
                    window.dataLayer.push({
                        'event': 'siteengagement.click',
                        'siteengagementType': 'links clicked',
                        'siteengagementName': eventLabel
                    });
                }
            }
        });

    }, 0);

    $('[data-siteengagement-event]').on('click', function(e) {
        let eventLabel = $(e.currentTarget).attr('data-event-label') ? $(e.currentTarget).attr('data-event-label') : $(e.currentTarget).text().trim();
        if (window.ga !== undefined) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'siteengagement.click',
                'siteengagementType': 'links clicked',
                'siteengagementName': eventLabel
            });
        }
    });

    /* Contact location link tracking **********************************/

    $(document).on('click', '[data-contact-location-event]', function (e) {
        var contactLink = $(this);
        var eventCategory = contactLink.attr('data-event-category');
        var eventAction = contactLink.attr('data-event-action');
        var distributorLocation = contactLink.attr('data-distributor-location');
        var eventLabel = contactLink.attr('data-event-label');

        // Google Analytics event tracking
        if (window.ga !== undefined) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': eventCategory,
                'leadgenerationType': eventAction,
                'leadgenerationName': eventLabel,
                'leaddistributorLocation': distributorLocation
            });
        }
    });

    /* Remove excessive whitespace in rte content *******************************/

    $('.rte p').each(function () {
        var html = $.trim($(this).html().replace('&nbsp;', ' '));
        $(this).html(html);
        if ($.trim($(this).text()) == '' && $('img, iframe', this).length == 0) {
            $(this).remove();
        }
    });

    /*********************************************************************************************/
    /* Animation */

    /* Animate up on scroll */
    var animUpSelectors = [];
    animUpSelectors.push('.text-right-panel .grid-panel-item__inner');
    animUpSelectors.push('.text-left-panel .grid-panel-item__inner');
    animUpSelectors.push('.js-crafted-header .crafted-header__img1');
    animUpSelectors.push('.js-crafted-header .crafted-header__video');
    animUpSelectors.push('.js-crafted-header .crafted-header__img-bottom');
    animUpSelectors.push('.crafted-cta__card');
    // Tentative use of .r-class selector
    animUpSelectors.push('.r-class .grid-panel-item__inner');

    $(animUpSelectors.join(', ')).each(function() {
        $(this).addClass('hidden-section');
    });

    function animUpElements() {
        var targetPos = scrollTop + windowHeight - 150;

        // Large up only
        if (state.isLargeDevice || state.isXLargeDevice) {
            $(animUpSelectors.join(', '))
                .not('.animUp')
                .each(function () {
                    if ($(this).offset().top < targetPos) {
                        $(this).removeClass('hidden-section').addClass('animUp');
                    }
                });
        }
    }

    animUpElements();

    /* Form Labels ************************************************************/

    $('.form-field input, .form-field textarea').each(function () {
        var value = $(this).val();
        $(this)
            .parent()
            .toggleClass('focus', value !== '');
    });

    $(document)
        .on('focus', '.form-field input, .form-field textarea', function () {
            $(this).parent().addClass('focus');
        })
        .on('blur', '.form-field input, .form-field textarea', function () {
            var value = $(this).val();
            $(this)
                .parent()
                .toggleClass('focus', value !== '');
        });

    /* Window event listeners *************************************************/

    $(window).on('scroll', function (e) {
        var bgVerticalOffset = (9 / 16) * windowWidth - $(window).scrollTop() * 0.2;

        $('.page-background').css({
            'background-position': '0 ' + bgVerticalOffset.toString() + 'px',
        });
    });

    $(window).on(
        'scroll',
        Foundation.utils.throttle(function (e) {
            scrollTop = $(window).scrollTop();

            updateSectionNavigation();
            updateStickyScrollDown();
            animUpElements();
        }, 100)
    );

    $(window).on('load', initCarousels);

    $(window).on(
        'resize orientationchange',
        Foundation.utils.throttle(function (e) {
            var oldState = {
                isSmallDevice: state.isSmallDevice,
                isMediumDevice: state.isMediumDevice,
                isLargeDevice: state.isLargeDevice,
                isPortraitOrientation: state.isPortraitOrientation
            };

            window.state = getDeviceState();

            windowWidth = $(window).width();
            windowHeight = $(window).height();
            scrollTop = $(window).scrollTop();
            contentTop = $('.content-top').offset()?.top;

            updateSectionNavigation();
            updateStickyScrollDown();
            animUpElements();

            if (
                oldState.isSmallDevice !== state.isSmallDevice ||
                oldState.isMediumDevice !== state.isMediumDevice ||
                oldState.isLargeDevice !== state.isLargeDevice ||
                oldState.isPortraitOrientation !== state.isPortraitOrientation
            ) {
                updateMediaGalleries();
            }
        }, 100)
    );

    /** Interaction logging across the entire site **/
    // Setup Events by Device Type

    if (whatInput.ask() === 'touch') {
        var interactionEventsArray = ['touchend'];
    } else {
        var interactionEventsArray = ['mouseup'];
    }

    // Initialize Interactor
    var elementsToTrack = [
        {
            element: 'a',
            events: interactionEventsArray,
        },
        {
            element: 'carousel__item',
            events: interactionEventsArray,
        },
        {
            element: 'slick-track',
            events: interactionEventsArray,
        },
    ];

    for (var i = 0; i < elementsToTrack.length; i++) {
        var e = elementsToTrack[i];
        new Interactor({
            interactionElement: e.element,
            interactionEvents: e.events,
            endpoint: '/umbraco/api/analyticsapi/postinteraction',
            endpointFull: '/umbraco/api/analyticsapi/postsession',
            async: true,
            debug: false,
            userId: _sessionId,
        });
    }
});
