import $ from 'jquery';

$.fn.localisationSelection = function (options) {
    const $this = this;

    const opts = $.extend(
        {},
        {
            sDuration: 0,
            onToggleModal() {},
        },
        options
    );

    $this.each((i, element) => {
        const container = $(element);

        const isModal = container.attr('data-is-modal') === 'True';
        let showModal = true;
        let modalTimeout;

        let languageRootRedirectUrl = '';
        const userPreference = {
            currentNodeId: container.data('current-node-id'),
        };

        function startLoading() {
            $('a[data-action="save-localisation-selection"]', container).addClass('loading');
        }

        function endLoading() {
            $('a[data-action="save-localisation-selection"]', container).removeClass('loading');
        }

        function collectUserPreference() {
            const languageSelect = $('.locator-modal__locator-section__select-language');
            const regionSelect = $('.locator-modal__locator-section__select-place');
            const distributorSelect = $(
                '.locator-modal__locator-section__distributor-dropdown__distributor-select'
            );

            userPreference.cultureNodeId = languageSelect
                .find('.custom-option.selection')
                .data('value');
            userPreference.regionNodeId =
                regionSelect.length > 0 ?
                    regionSelect.find('.custom-option.selection').data('value') :
                    -1;
            userPreference.distributorLocationNodeId =
                distributorSelect.length > 0 ?
                    distributorSelect.find('.custom-option.selection').data('value') :
                    -1;
        }

        function setUserLocalisationPreference(callback) {
            collectUserPreference();
            $.post(
                '/umbraco/api/localisationapi/setuserlocalisationpreference/',
                userPreference,
                (data) => {
                    if (typeof callback === 'function') callback(data);
                },
                'json'
            );
        }

        function geoSuccess(position) {
            const latitude = position.coords.latitude; // 15;
            const longitude = position.coords.longitude; // 19;

            const geocoder = new google.maps.Geocoder();
            const location = new google.maps.LatLng(latitude, longitude);

            geocoder.geocode(
                {
                    location,
                },
                (results, status) => {
                    if (status === google.maps.GeocoderStatus.OK) {
                        // console.log(results);
                        $.post(
                            '/umbraco/api/localisationapi/setpreferencebygeolocation/',
                            {
                                latitude,
                                longitude,
                                results: JSON.stringify(results),
                            },
                            (data) => {
                                updateLocalisation(true, data);
                            },
                            'json'
                        );
                    } else {
                        console.log(
                            'Geocode was not successful for the following reason: ' + status
                        );
                        // endLoading();
                        // toggleModal();
                        updateLocalisation(true);
                    }
                }
            );
        }

        function geoError(error) {
            console.log('Geolocation error: ' + error.code);
            // Known issue in Firefox. Permission decision deferred with 'Not Now' does not fire callback
            // https://bugzilla.mozilla.org/show_bug.cgi?id=675533

            updateLocalisation(true);
        }

        function getCurrentPosition() {
            if (navigator.geolocation && googleMapsApiReady) {
                startLoading();
                navigator.geolocation.getCurrentPosition(geoSuccess, geoError, {
                    timeout: 5000,
                });
            } else {
                console.log('No browser geolocation');
                toggleModal();
            }
        }

        function setLocalContactLocation(preferencesJson, hasOverlayMenu) {
            const localContactLocation = $('#local-contact-location');
            const currentLocationId = localContactLocation.attr('data-location-id');

            $.post(
                '/umbraco/surface/localisationsurface/localcontactlocation/',
                {
                    preferencesJson,
                    currentNodeId: userPreference.currentNodeId,
                    hasOverlayMenu,
                },
                (data) => {
                    const newLocationId = $(data).attr('data-location-id');
                    if (newLocationId !== currentLocationId) {
                        localContactLocation
                            .hide()
                            .empty()
                            .append($('.location', data))
                            .attr('data-location-id', newLocationId)
                            .fadeIn(500);
                    }
                },
                'html'
            );
        }

        function setLocalDealershipLink(preferencesJson) {
            const localDealershipLink = $('#local-dealership-link');

            $.post(
                '/umbraco/surface/localisationsurface/localdealershiplink/',
                {
                    preferencesJson,
                    currentNodeId: userPreference.currentNodeId,
                },
                (data) => {
                    localDealershipLink.empty().append($('a', data));
                },
                'html'
            );
        }

        function setLocalLogoStrapline(preferencesJson) {
            const logoStrapline = $('#logo-strapline');
            const currentText = $.trim(logoStrapline.text());

            $.post(
                '/umbraco/surface/localisationsurface/locallogostrapline/',
                {
                    preferencesJson,
                    currentNodeId: userPreference.currentNodeId,
                },
                (data) => {
                    const newText = $.trim($(data).text());
                    if (newText !== currentText) {
                        logoStrapline.hide().html($(data).html());
                        logoStrapline.fadeIn(500);
                    }
                },
                'html'
            );
        }

        function initCustomSelect() {
            $('.custom-select').each(function () {
                const classes = $(this).attr('class');
                const dataAction = $(this).attr('data-action');
                const backroundColor = $(this).attr('data-backround-color');
                let template = '<div class="' + classes + '" data-action="' + dataAction + '">';
                template += '<span style="background-color: ' + backroundColor + ' !important;" class="custom-select-trigger"><span class="select-header">' + $(this).attr('placeholder') + '</span></span>';
                template += '<div class="custom-options">';
                $(this).find('option').each(function () {
                    const selectedClass = $(this).attr('selected') ? 'selection' : '';
                    template += '<span style="background-color: ' + backroundColor + ' !important;" class="custom-option ' + $(this).attr('class') + ' ' + selectedClass + '" data-value="' + $(this).attr('value') + '">' + $(this).html() + '</span>';
                });
                template += '</div></div>';

                if ($(this).hasClass('custom-select__large')) {
                    $(this).wrap('<div class="custom-select-wrapper__large"></div>');
                } else {
                    $(this).wrap('<div class="custom-select-wrapper"></div>');
                }

                $(this).hide();
                $(this).after(template);
                addSelectedElement($(this).next());
            });
            $('.custom-option:first-of-type').hover(function () {
                $(this).parents('.custom-options').addClass('option-hover');
            }, function () {
                $(this).parents('.custom-options').removeClass('option-hover');
            });
            $('.custom-select-trigger').on('click', function () {
                toggleLocatorSelect(this);
                event.stopPropagation();
            });
            $('.custom-select').on('keyup', function (event) {
                if (event.key === 'Enter') {
                    const element = $(this).children()[0];
                    toggleLocatorSelect(element);

                    if (!$(event.target).hasClass('custom-option')) {
                        event.stopPropagation();
                    }
                }
            });

            var toggleLocatorSelect = function toggleLocatorSelect(element) {
                if (!$(element).parents('.custom-select').hasClass('opened')) {
                    $('.custom-select.opened').removeClass('opened');
                    $('.custom-select').find('.custom-select-trigger').css('border-bottom', '0px');
                    $(element).parents('.custom-select').find('.custom-options').removeClass('active');
                    $(element).parents('.custom-select').find('.custom-options').children()
                        .each(function () {
                            $(this).attr('tabindex', -1);
                        });
                }

                $(element).parents('.custom-select').toggleClass('opened');
                $(element).css('border-bottom', '1px solid rgba(112, 112, 112, 0.4)');
                $(element).parents('.custom-select').find('.custom-options').toggleClass('active');
                $(element).parents('.custom-select').find('.custom-options').children()
                    .each(function () {
                        $(this).attr('tabindex', 0);
                    });

                if (!$(element).parents('.custom-select').hasClass('opened')) {
                    $(element).css('border-bottom', '0px');
                }

                const htmlHeight = $('html').height();
                const elementScrollPosition = $('.locator-modal__locator-section__first-dropdowns').offset().top;

                if (htmlHeight - elementScrollPosition <= 200) {
                    $('.locator-modal__locator-section').animate({
                        scrollTop: 100
                    }, 1000 // speed
                    );
                }
            };

            const selectCustomOption = function selectCustomOption(element) {
                $(element).parents('.custom-select-wrapper').find('select').val($(element).data('value'));
                $(element).parents('.custom-options').find('.custom-option').removeClass('selection');
                $(element).addClass('selection');
                $(element).parents('.custom-select').removeClass('opened');
                $(element).parents('.custom-select').find('.custom-select-trigger > span').text($(element).text());
            };

            $('.custom-option').on('click', function () {
                selectCustomOption(this);
            });
            $('.custom-option').on('keyup', function () {
                if (event.key === 'Enter') {
                    selectCustomOption(this); // event.stopPropagation();
                }
            });

            function addSelectedElement(element) {
                const selectedOption = $(element[0]).find('.custom-options').find('.selection');
                $(element[0]).find('.custom-select-trigger').html('<span class="select-header">'.concat(selectedOption.text(), '</span>'));
                $(element[0]).find('.custom-select-wrapper').find('select').val(selectedOption.data('value'));
            }
        }

        function updateLocalisation(
            doRedirect,
            preferencesData,
            isSelectedDistributors,
            mobileActiveTab
        ) {
            if (doRedirect && languageRootRedirectUrl !== '') {
                window.location.assign(languageRootRedirectUrl);
            } else {
                const preferencesJson =
                    preferencesData !== undefined ?
                        JSON.stringify(preferencesData) :
                        $('input[name="PreferencesJson"]', container).val();

                let loadComplete = 0;

                $this.each(function (i) {
                    const localisationSelection = $(this);
                    const localisationSelectionId = localisationSelection.attr('id');
                    const innerSelector = '#' + localisationSelectionId + '-inner';

                    localisationSelection.load(
                        '/umbraco/surface/localisationsurface/localisationsection/ ' +
                            innerSelector,
                        {
                            preferencesJson,
                            containerId: localisationSelectionId,
                            currentNodeId: localisationSelection.attr('data-current-node-id'),
                            isModal: localisationSelection.attr('data-is-modal') === 'True',
                            showModal,
                            isSelectedDistributors,
                            mobileActiveTab,
                        },
                        () => {
                            loadComplete++;
                            setLocalContactLocation(preferencesJson, true);
                            // don't fire all these twice
                            if (loadComplete === 2) {
                                collectUserPreference();

                                setLocalDealershipLink(preferencesJson);
                                setLocalLogoStrapline(preferencesJson);

                                endLoading();
                                toggleModal();
                            }
                            initCustomSelect();
                            $('div.locator-modal__locator-section__select-place').attr(
                                'tabindex',
                                0
                            );
                            $('div.locator-modal__locator-section__select-language').attr(
                                'tabindex',
                                0
                            );
                            $(
                                'div.locator-modal__locator-section__distributor-dropdown__distributor-select'
                            ).attr('tabindex', 0);
                            $('div.locator-modal__locator-section__select-place').focus();
                        }
                    );
                });

                $(
                    '#local-distributors, #footer-local-distributors, #contact-local-distributors'
                ).each(function () {
                    const localDistributorsContainer = $(this);

                    localDistributorsContainer.load(
                        '/umbraco/surface/localisationsurface/localdistributors/',
                        {
                            preferencesJson,
                            localDistributorsContainerId: localDistributorsContainer.attr('id'),
                            showMap: $('.map', localDistributorsContainer).length > 0,
                            pageLinkText: localDistributorsContainer.attr('data-page-link-text'),
                            contactLinkText: localDistributorsContainer.attr(
                                'data-contact-link-text'
                            ),
                            currentNodeId: localDistributorsContainer.attr('data-current-node-id'),
                            limit: localDistributorsContainer.attr('data-limit'),
                        },
                        () => {
                            if (localDistributorsContainer.localDistributors) {
                                localDistributorsContainer.localDistributors('update', true);
                            }
                        }
                    );
                });
            }
        }

        function toggleModal() {
            if (isModal) {
                clearTimeout(modalTimeout);
                container.toggleClass('hidden', !showModal);
                opts.onToggleModal(showModal);
            }
        }

        function updateDistributorCookie(isChosen, locationData) {
            const date = new Date();
            const days = 365;

            // Get unix milliseconds at current time plus number of days
            date.setTime(+date + days * 24 * 60 * 60 * 1000);

            const host = window.location.host.split('.');
            let domain = '';

            // exception for uat, because we can't set cookie for the whole domain .azurewebsites.net
            if (host[host.length - 2] === 'azurewebsites') {
                domain = window.location.host;
            } else {
                domain = host[host.length - 2] + '.' + host[host.length - 1];
            }

            if (isChosen) {
                document.cookie = `isDistributorChosen=true;expires=${date.toUTCString()};Path=/;secure;domain=${domain};`;
                $('.locator-open-icon img').attr('src', '/images/distributor-roundel-chosen_dark.svg');
                $('.locator-open-icon').removeClass('small-icon');
            } else {
                document.cookie = `isDistributorChosen=false;expires=${date.toUTCString()};Path=/;secure;domain=${domain};`;
            }

            if (locationData != undefined) {
                document.cookie = `pyLocalisationPrefs=${JSON.stringify(
                    locationData
                )};expires=${date.toUTCString()};Path=/;secure;domain=${domain};`;
            }

            // delete cookies with subdomain, because now we are using only .princessyachts.com cookies
            if (host[host.length - 2] !== 'azurewebsites') {
                document.cookie = `pyLocalisationPrefs=;expires=Thu, 01 Jan 1970 00:00:00 GMT;Path=/;domain=${window.location.host}`;
                document.cookie = `pyLocalisationPrefs=;expires=Thu, 01 Jan 1970 00:00:00 GMT;Path=/;domain=.${window.location.host}`;
                document.cookie = 'pyLocalisationPrefs=;expires=Thu, 01 Jan 1970 00:00:00 GMT;Path=/;';
            }
        }

        // Header bar (modal) instance is shown on load
        if (isModal) {
            showModal = !container.hasClass('hidden');
            // console.log('localisationSelection, showModal', showModal);
            toggleModal();
            if (showModal) getCurrentPosition();
        }

        // User changes culture (language) selection
        container.on('click', 'div[data-action="select-language"]', (e, run) => {
            setUserLocalisationPreference((data) => {
                // console.log('select-language.change', data);
                const selectedValue = $(
                    '.locator-modal__locator-section__select-language .custom-options'
                )
                    .find('.selection')
                    .data('value');

                languageRootRedirectUrl = $('#select-language-section')
                    .children()
                    .filter((x, y) => $(y).val() == selectedValue)
                    .data('url');

                // console.log('select-language.change', languageRootRedirectUrl);
                updateLocalisation(false, data, false, 1);
            });
        });

        // User changes region (nationality) selection
        container.on('click', 'div[data-action="select-region"]', (e) => {
            setUserLocalisationPreference((data) => {
                // console.log('select-region.change', data);
                // when changed location  distributors is not available anymore
                data.DistributorLocationNodeId = -1;
                updateLocalisation(false, data, false, 2);
            });
        });

        // User changes region (nationality) selection by pressing enter
        container.on('keyup', 'div[data-action="select-region"]', (e) => {
            if (e.key === 'Enter') {
                setUserLocalisationPreference((data) => {
                    data.DistributorLocationNodeId = -1;
                    updateLocalisation(false, data, false, 2);
                    $('div.locator-modal__locator-section__select-place').attr('tabindex', 0);
                    $('div.locator-modal__locator-section__select-language').attr('tabindex', 0);
                    $(
                        'div.locator-modal__locator-section__distributor-dropdown__distributor-select'
                    ).attr('tabindex', 0);
                    $('div.locator-modal__locator-section__select-place').focus();
                });
            }
        });

        // User changes distributor selection
        container.on('click', 'div[data-action="select-distributor"]', (e) => {
            setUserLocalisationPreference((data) => {
                if (data.DistributorLocationNodeId != -1) {
                    updateLocalisation(false, data, true, 3);
                }
            });
        });

        container.on('keyup', 'div[data-action="select-distributor"]', (e) => {
            if (e.key === 'Enter') {
                setUserLocalisationPreference((data) => {
                    if (data.DistributorLocationNodeId != -1) {
                        updateLocalisation(false, data, true, 3);
                    }
                });
            }
        });

        // User clicks 'Update settings' button
        container.on('click', 'a[data-action="save-localisation-selection"]', (e) => {
            e.preventDefault();
            openLocator();
            showModal = false;

            setUserLocalisationPreference((data) => {
                if (data != undefined && data.DistributorLocationNodeId > 0) {
                    // console.log(data.DistributorLocationNodeId);
                    updateDistributorCookie(true, data);
                }

                if (userPreference.regionNodeId === 0) {
                    // 'Rest of world' selected
                    getCurrentPosition();
                } else if (userPreference.regionNodeId > 0) {
                    // Region selected
                    startLoading();
                    updateLocalisation(true);
                    updateDistributorCookie(true);
                } else if (languageRootRedirectUrl !== '') {
                    // Only language selected
                    startLoading();
                    window.location.assign(languageRootRedirectUrl);
                } else {
                    toggleModal();
                }
            });
        });
    });

    return $this;
};
